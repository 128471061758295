import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { withPrefix, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dora.css";
import Helmet from "react-helmet";

const BackgroundImage = require("gatsby-background-image").default;

const AboutPage = () => (
	<StaticQuery
		query={graphql`
			query {
				image1: file(relativePath: { eq: "shutterstock_verzerrt.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				image2: file(relativePath: { eq: "shutterstock_autism-sign.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 700) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={(data) => <AboutPage1 data={data} />}
	/>
);

interface ImageTitleText2ColRowProps {
	readonly imageFluid: any;
	readonly imagePlacement: "left" | "right";
	readonly imageSize?: "cover" | "contain";
	readonly imagePosition?: string; // e.g. top, bottom, etc.  See the `background-position` property
	readonly title: JSX.Element | string | null;
	readonly text: JSX.Element | string;
	readonly textColor?: string;
	readonly backgroundStyle?: React.CSSProperties;
	readonly minHeights: [string, string, string];
}

const ImageTitleText2ColRow = (props: ImageTitleText2ColRowProps) => {
	const imageSize = props.imageSize || "cover";
	const imagePosition = props.imagePosition || "center";

	const Row = styled.div`
		width: 100%;
		min-height: ${props.minHeights[0]};
		@media (min-width: 742px) {
			min-height: ${props.minHeights[1]};
			display: flex;
			align-items: center;
		}
		@media (min-width: 920px) {
			min-height: ${props.minHeights[2]};
		}
	`;

	const Col50 = styled.div`
		width: 100%;
		@media (min-width: 742px) {
			width: 50%;
		}
	`;
	const StyledBackgroundImage = styled(BackgroundImage)`
		background-position: ${imagePosition};
		background-size: ${imageSize};
		padding-top: 100%;
		z-index: 0;
		@media (min-width: 742px) {
			height: 480px;
			padding: 0;
		}
		@media (min-width: 920px) {
			height: 700px;
		}
		&:before,
		&:after {
			background-size: ${imageSize};
			background-position: ${imagePosition};
		}
	`;

	/** Ensure padding around the content */
	const Padding = styled.div`
		padding: 3em;
		margin: 0 auto;
	`;

	const ContentSizingAndAnchoring = styled.div<{ readonly imagePlacement: "left" | "right" }>`
		@media (min-width: 742px) {
			width: 260px;
			margin: ${props.imagePlacement === "left" ? "0 auto 0 2em" : "0 2em 0 auto"};
		}
		@media (min-width: 920px) {
			width: 360px;
			font-size: 110%;
		}
	`;

	return (
		<Row style={props.backgroundStyle}>
			<Col50 style={props.imagePlacement === "left" ? { order: 1 } : { order: 2 }}>
				<StyledBackgroundImage
					fluid={props.imageFluid}
					// imageStyle={{ backgroundSize: "contain" }}
				/>
				{/* <Img fluid={props.fluidImage} /> */}
			</Col50>
			<Col50
				style={{
					...(props.imagePlacement === "left" ? { order: 2 } : { order: 1 }),
					...(props.textColor ? { color: props.textColor } : {}),
				}}
			>
				<Padding>
					<ContentSizingAndAnchoring imagePlacement={props.imagePlacement}>
						{typeof props.title === "string" ? <h3>{props.title}</h3> : props.title}
						{typeof props.text === "string" ? (
							<p style={{ lineHeight: "1.75em" }}>{props.text}</p>
						) : (
							props.text
						)}
					</ContentSizingAndAnchoring>
				</Padding>
			</Col50>
		</Row>
	);
};

const minHeights: [string, string, string] = ["auto", "480px", "700px"];

const AboutPage1 = ({ data }: { data: any }) => (
	<Layout>
		<Helmet>
			<link
				rel="stylesheet"
				href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
				integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
				// crossorigin="anonymous"
			/>
			<link
				href="https://cdn.materialdesignicons.com/4.8.95/css/materialdesignicons.min.css"
				rel="stylesheet"
			></link>
		</Helmet>
		<SEO title="Definition" />

		<header
			id="showcase"
			style={{
				backgroundColor: "var(--galoxeeOrange)",
				margin: "0 auto",
				padding: "3em",
				width: "100%",
			}}
		>
			<div className="container">
				<h1
					style={{
						fontSize: "2em",
						maxWidth: "50em",
						margin: "0 auto",
						textAlign: "center",
						padding: 0,
					}}
				>
					Autismus-Spektrum-Störung (ASS)
				</h1>
			</div>
		</header>

		<ImageTitleText2ColRow
			imageFluid={data.image1.childImageSharp.fluid}
			imagePlacement="left"
			imageSize="cover"
			title={null}
			text={
				<div>
					<h5>Liebe Besucherin, lieber Besucher!</h5>
					<br></br>
					Auf dieser Seite findest Du Informationen zu folgendem Thema:
					<ul className="list-unstyled">
						<br></br>
						<li>- Was ist eine Autismus-Spektrum-Störung?</li>
						<li>- Woran erkennt man Autismus?</li>
						<li>- Was ist der Unterschied zwischen:</li>
						<ul>
							<li>Frükindlicher Autismus</li>
							<li>Atypischer Autismus</li>
							<li>Highfunctioning Autismus</li>
							<li>Asperger Autismus</li>
						</ul>
					</ul>
					Wenn Du möchtest, kannst Du Dir auch{" "}
					<a href="https://www.youtube.com/watch?v=P_vcBWGbsK8" target="_blank">
						mein Video
					</a>{" "}
					zu diesem Thema ansehen.
				</div>
			}
			minHeights={minHeights}
		/>

		<ImageTitleText2ColRow
			imageFluid={data.image2.childImageSharp.fluid}
			imagePlacement="right"
			title={null}
			text={
				<div>
					<h5>Unterschiede verschwimmen</h5>
					<p>
						Jemand, der als Kind mit Frühkindlichem Autismus diagnostiziert wurde, kann
						später die Diagnose Asperger erhalten.
					</p>
					<br></br>
					<h5>Einfach einzigartig</h5>
					<p>
						Jeder Mensch ist ein Individuum, ob mit oder ohne Autismus. Deshalb sind
						auch die Anzeichen für den Autismus in jedem Autisten einzigartig
						ausgeprägt.
						<br></br>
					</p>
					Wenn Du weiterliest findest Du mehr Informationen zu diesem Thema. Eine Tabelle
					für einen schnellen Überblick über die einzelnen Autismus-Arten gibt es hier
					auch.
				</div>
			}
			textColor="white"
			backgroundStyle={{
				backgroundColor: "var(--galoxeeBlue)",
			}}
			minHeights={minHeights}
		/>

		<div
			style={{ maxWidth: "40rem", padding: "3rem 0", margin: "0 auto", textAlign: "justify" }}
		>
			<h2>Autismus-Spektrum-Störung</h2>
			<p>
				<span
					className="mdi mdi-group"
					style={{
						fontSize: "3rem",
						float: "left",
						marginRight: "1rem",
					}}
				/>
				Der Begriff Autismus-Spektrum-Störung umfaßt alle Arten von Autismus:
				Frühkindlicher, Atypischer, Asperger und Highfunctioning Autismus. Die Übergänge
				zwischen den einzelnen Formen sind oft fließend und eine genaue Abgrenzung kann
				manchmal schwierig sein. Deshalb ist man mehr und mehr dazu übergegangen, von einer
				generellen Autismus-Spektrum-Störung zu sprechen. Autismus-Spektrum-Störung bedeutet
				also, dass unterschiedlich starke Ausprägungen des Störungsbildes vorhanden sind.
				Das Spektrum reicht dabei von schwerer Behinderung bis hin zu intellektueller
				Hochbegabung. Eine Hochbegabung ist zwar eher selten, aber durchaus beim Autimus
				bekannt.
				<p>
					<em>
						(Symbol: Grosses Quadrat steht für das Autismus Spektrum. Alle anderen
						Autismus Arten sind im Spektrum enthalten.)
					</em>
				</p>
			</p>

			<h5>Frühkindlicher Autismus</h5>
			<p>
				<span
					className="mdi mdi-vector-combine"
					style={{
						fontSize: "3rem",
						float: "left",
						marginRight: "1rem",
					}}
				/>
				Auf der einen Seite des Spektrum findet man den Frühkindlichen Autismus, der auch
				Kanner Autismus oder Kanner Syndrom genannt wird. Diese Form des Autismus bedeutet
				eine schwerwiegende Entwicklungsstörung mit starker Behinderung. Weil die
				Intelligenz, die mit Frühkindlichen Autismus einhergeht, tendenziell niedrig ist,
				spricht man manchmal von Low Functioning Autismus (niedrig funktionaler Autismus).
				Die meisten Merkmale, die für das Autismus Spektrum typisch sind, sind beim
				Frühkindlichen Autismus sehr stark ausgeprägt. Ein selbstständiges Leben ist dem
				erwachsenen Autisten in den allermeisten Fällen nicht möglich. Er wird immer auf
				Hilfe angewiesen bleiben.
				<p>
					<em>
						(Symbol: Im Frühkindlichen Autismus sind alle Autismus-Merkmale sehr
						deutlich ausgeprägt. Die Linien der Quadrate sind durchgehen und ohen
						Unterbrechung.)
					</em>
				</p>
			</p>

			<h5>Atypischer Autismus</h5>
			<p>
				<span
					className="mdi mdi-vector-difference"
					style={{
						fontSize: "3rem",
						float: "left",
						marginRight: "1rem",
					}}
				/>
				Beim "Atypischen Autismus" sind die Anzeichen des Frühkindlichen Autismus zwar
				vorhanden, aber in einer untypischen Form. Es gibt zwei Varianten:
				<li>untypischer Beginn</li>
				<li>untypische Symptomatik</li>
				Entweder beginnt die Autismus-Symptomatik später, als es beim typischen
				Frühkindlichen Autismus der Fall ist. Oder es sind nicht alle typischen Autismus
				Merkmale vorhanden (siehe Tabelle unten).
				<p>
					<em>
						(Symbol: Der Atypische Autismus hat nicht alle Merkmale des Frühkindlichen
						Autismus. Deshalb sind die Linien der Quadrate in der Mitte unterbrochen.)
					</em>
				</p>
			</p>

			<h5>High Functioning Autismus</h5>
			<p>
				<span
					className="mdi mdi-vector-difference-ba"
					style={{
						fontSize: "3rem",
						float: "left",
						marginRight: "1rem",
					}}
				/>
				High Functioning Autismus (hochfunktionaler Autismus) bedeuted, dass es sich um eine
				Form des Frühkindlichen Autismus handelt mit einem mittleren oder hohen
				Intelligens-Niveau. Gerade beim High Functioning Autismus wird es sehr deutlich, wie
				fließend die Übergänge auf dem Spektum sein können. Durch die recht hohe Intelligenz
				können die Betroffenen sich sehr weit entwickeln. Viele ihrer ursprünglich stark
				autistischen Züge schwächen sich dadurch deutlich ab. Im Laufe der Zeit ist der
				Highfunctioning Autismus oftmals gar nicht mehr vom Asperger Austismus zu
				unterscheiden.
				<p>
					<em>
						(Symbol: Der Highfunctioning hat zwar Anfangs die Merkmale des
						Frühkindlichen Autismus, ist aber später oftmals nicht vom Asperger Autismus
						zu unterscheiden. Die Linien symbolisieren die Zugehörigkeit zum
						Frühkindlichen Autismusu und die Ähnlichkeit zum Asperger Autismus.)
					</em>
				</p>
			</p>

			<h5>Asperger Autismus</h5>
			<p>
				<span
					className="mdi mdi-vector-intersection"
					style={{
						fontSize: "3rem",
						float: "left",
						marginRight: "1rem",
					}}
				/>
				Der Asperger Autismus wird manchmal als eine schwache oder milde Form des Autismus
				betrachtet. Asperger gehört nicht zur Gruppe des Frühkindlichen Autismus. Grund
				dafür sind die komplett andere Sprachentwicklung in den ersten Lebensjahren und
				motorische Probleme. Anders als alle oben genannten Autismus-Formen fangen
				Asperger-Kinder sehr früh an zu sprechen. Ihre Grammatik ist dabei auffällig gut und
				sie klingen oft belehrend und altklug. Sie kommunizieren überwiegend auf der
				sogenannten Sach-Ebene und verstehen alles sehr wörtlich. Das führt häufig zu
				gravierenden Mißverständnissen. Da Asperger aber, abgesehen von ihrer motorischen
				Ungeschicktheit, sehr "normal" wirken, werden ihre Reaktionen und Äußerungen oft als
				beleidigend oder bewußt störend wahrgenommen. In den allermeisten Fällen sind
				Asperger dann verwirrt und können die plötzliche Abweisung des Gegenüber nicht
				verstehen. Bemerkt wird der Asperger Autismus nicht selten von den Eltern selbst,
				weil die Kinder bei den ärztlichen Kontrolluntersuchungen zunächst nicht umbedingt
				auffallen. Im Laufe der Jahre können Begleiterkrankungen, wie Depression,
				Angststörungen oder AD(H)S hinzukommen, die letztliche die Autismus-Diagnose
				hervorrufen.
				<p>
					<em>
						(Symbol: Der Asperger Autismus gehört nicht zum Frühkindlichen Autismus. Die
						grundlegenden Merkmale des Autismus sind vorhanden, aber die Entwicklung ist
						eine andere. Daher deuten die Linien die Quadrate nur in ihrer Form an.)
					</em>
				</p>
			</p>
			<br />
		</div>

		<div style={{ backgroundColor: "#ddd" }}>
			<div className="container">
				<div className="row" style={{ maxWidth: "100%" }}>
					<div className="col col-12">
						<h2 className="text-center">
							Woran erkennt man die Autismus-Spektrum-Störung?
						</h2>
						Drei Merkmale bilden die Grundlage für eine Autismus-Spektrum-Störung. Das
						sind Auffälligkeiten, die in allen Formen des Autismus vorkommen. Wie stark
						sie sichtbar sind, ist immer von der jeweiligen Person abhängig. Frauen sind
						genauso betroffen wie Männer. Sie verstecken ihre Probleme tendeziell aber
						mehr und versuchen unauffällig zu sein. Doch das sogenannte "Masking" kostet
						enorm viel Kraft. Häufig kommen dann Überforderung, Depression und anderen
						psychischen Krankheiten hinzu. Autisten haben eine ganz andere Wahrnehmung
						und deshalb eine andere Art auf ihre Umwelt zu reagieren. Folgende
						Auffälligkeiten sind bei allen Autisten im gesellschaftlichen Miteinander
						mehr oder weniger intensiv zu beobachten:
					</div>
				</div>
				<div className="row text-center" style={{ maxWidth: "100%" }}>
					<div className="col md-12">
						<span className="mdi mdi-account-group" style={{ fontSize: "3rem" }} />
						<h3>Soziale Interaktion</h3>
					</div>
				</div>
				<div className="row" style={{ maxWidth: "100%" }}>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Beispiel</h5>
								<p>
									Ein autistisches Mädchen reagiert nicht, als die Spielkameradin
									sich sichtbar verletzt und zu weinen anfängt. Dadurch wirkt das
									autistische Mädchen emotionslos, weil es nicht reagiert, wie wir
									es erwarten: Es hält nicht inne, es tröstet die Freundnin nicht
									und holt auch keine Hilfe. Es lacht stattdessen vielleicht
									sogar.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Problem</h5>
								<p>
									Autisten können die Mimik des Anderen zwar sehen, verstehen aber
									die Bedeutung dahinter nicht oder sehen nicht die gesamte
									Situation, sondern nur Ausschnitte davon. Das Weinen der
									Freundin und die Verletzung werden nicht sofort als etwas
									erkannt, auf das man mit Besorgnis reagieren sollte. Das
									autistische Gehirn fokussiert stattdessen auf Details. Die
									herunterlaufenden Bluttropfen des aufgeplatzen Kniesein ergeben
									ein faszinierendes Muster (Detail, statt die Gesamtsituation).
									Das Kind freut sich und lacht.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Mißverständnis</h5>
								<p>
									Mitgefühl wäre vorhanden, wenn das autistische Mädchen sofort
									verstanden hätte, was die Freundin gerade erlebt. Autisten sind
									nicht emotionslos, wie oft klischeehaft behauptet wird. Sie
									müssen aber mühevoll lernen, auf welche Signale sie in ihrer
									Umwelt achten müssen. Sie müssen lernen diese zu erkennen,
									schnell einzuordnen, um dann entsprechend reagieren zu können.
								</p>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row text-center" style={{ maxWidth: "100%" }}>
					<div className="col md-12">
						<span
							className="mdi mdi-frequently-asked-questions"
							style={{ fontSize: "3rem" }}
						/>
						<h3>Soziale Kommunikation</h3>
					</div>
				</div>
				<div className="row" style={{ maxWidth: "100%" }}>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Beispiel</h5>
								<p>
									Der Lehrer sagt: "Anton kannst du das Fenster zu machen? - Anton
									antwortet: "Ja!", bleibt sitzen und reagiert nicht weiter. Der
									Lehrer wird wütend, weil Anton das Fenster nicht geschlossen hat
									und fühlt sich provoziert.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Problem</h5>
								<p>
									Anton hat die Frage des Lehrers aus seiner Sicht korrekt
									beantwortet: "Ja (ich bin dazu fähig, ein Fenster zu
									schließen)". Er versteht nicht, warum der Lehrer wütend ist und
									ihn als frech bezeichnet. Wieder einmal hat er etwas falsch
									gemacht, ohne zu wissen, was es war. Die Klasse lacht. Anton
									schämt sich und seine Verunsicherung wächst. Er schweigt.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Mißverständnis</h5>
								<p>
									Anton ist Autist und versteht Aussagen sehr wörtlich. Er
									kommuniziert auf der Sachebene. Eine Aufforderung erkennt er in
									der Aussage des Lehrers nicht. Er hört nur die Frage, ob er in
									der Lage ist, ein Fenster zu schließen und antwortet
									wahrheitsgemäß mit "Ja". Damit Anton aufsteht und das Fenster
									schließt, hätte der Lehrer "Anton, es ist kalt. Mach bitte das
									Fenster zu!" sagen müssen. Diese Art von Mißverständnissen,
									bestimmen den Alltag von Autisten und führen oft zu Frust und
									Verzweiflung.
								</p>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className="row text-center" style={{ maxWidth: "100%" }}>
					<div className="col md-12">
						<span className="mdi mdi-webhook" style={{ fontSize: "3rem" }} />
						<h3>
							Stereotypes Verhalten (alles muss gleich bleiben oder sich wiederholen)
						</h3>
					</div>
				</div>
				<div className="row" style={{ maxWidth: "100%" }}>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Beispiel</h5>
								<p>
									Situation im Dorfkindergarten: Die ganze Gruppe holt das
									Geburtstagskind mit dem Bollerwagen und Gesang zu Hause ab. Das
									Geburtstagskind bekommt eine Krone, es darf als einziges Kind im
									Bollerwagen sitzen, wird bejubelt und feierlich in den
									Kindergarten gebracht. Alle sind bester Laune, nur ein Kind
									nicht: Das Geburtstagskind selbst. Verkrampft und mit starrer
									Mine sitzt es da. Im Kindergarten angekommen, verzieht sich es
									sich in eine Ecke und fängt an, sich gleichmässig hin und her zu
									wiegen. Die Erzieher sind erstaunt, denn normalerweise sind die
									Kleinen sehr stolz bei dieser Geburtstags-Aktion.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Problem</h5>
								<p>
									Autistische Kinder brauchen gleiche Tagesabläufe. Eine feste
									Struktur bietet ihnen Sicherheit. Das Kind weiß, was geschehen
									wird und kann sich innerlich darauf vorbereiten. Die Struktur
									wurde gestört: Es darf nicht zur gewohnten Uhrzeit in den
									Kindergarten gehen, es muss warten, wird abgeholt und der
									Geburtstags-Trupp macht einen Umweg. Der Lärm von den auf der
									Strasse singenden Kinder reizt das Kind zusätzlich und es flieht
									in die Ecke. Die Ecke bietet Schutz und die sich wiederholenden
									Bewegungen des Schaukelns wirken beruhigend.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">Mißverständnis</h5>
								<p>
									Man ist leicht versucht, die Situation mit Aufmunterungen retten
									zu wollen. Aber das kann alles noch schlimmer machen und zur
									Eskalation führen. Nicht selten folgt dann ein emotionaler
									Ausbruch ("Meltdown"). Manche Kinder schlagen dabei ihren Kopf
									gegen die Wand, oder sie schlagen um sich. Manche fangen an, zu
									schreien. Oft folgt dann eine Bestrafung für unartiges Benehmen,
									was absolut ungerecht ist. Richtig ist es, dem Kind seinen
									Rückzugsraum zu lassen bis es sich beruhigt hat. Erst dann soll
									das Kind wieder in die Gruppe integriert werden.
								</p>
							</div>
						</div>
					</div>
				</div>
				<br />
			</div>
		</div>
		<div style={{ maxWidth: "75em", padding: "3em", margin: "0 auto", textAlign: "justify" }}>
			Die folgende Tabelle bietet einen schnellen Überblick zu den Unterschieden der
			Unterarten des gesamten Spektrums. Aber nicht vergessen: Autismus ist ein
			Spektrum-Störung und die Abgrenzung ist oftmals nicht eindeutig. Die erste Diagnose
			meines Sohnes war Atypischer Autismus. Nur ein halbes Jahr später sprachen die
			Psychologen im Autismus-Therapie-Zentrum (ATZ) von Asperger Autismus und erwähnten noch
			den High Functioning Autismus. Bis heute ist nicht ganz klar, in welche der Gruppen er
			am besten passt, auch wenn ganz klar ist, dass mein Sohn auf dem Autismus-Spektrum ist.
		</div>
		<div style={{ maxWidth: "75em", padding: "3em", margin: "0 auto", textAlign: "left" }}>
			<table className="table table-striped table-bordered">
				<thead>
					<tr>
						<th />
						<th>
							<span className="mdi mdi-vector-combine" style={{ fontSize: "3rem" }} />
							<br />
							Frühkindlicher Autismus
						</th>
						<th>
							<span
								className="mdi mdi-vector-difference"
								style={{ fontSize: "3rem" }}
							/>
							<br />
							Atypischer Autismus
						</th>
						<th>
							<span
								className="mdi mdi-vector-difference-ba"
								style={{ fontSize: "3rem" }}
							/>
							<br />
							High Functioning Autismus
						</th>
						<th>
							<span
								className="mdi mdi-vector-intersection"
								style={{ fontSize: "3rem" }}
							/>
							<br />
							Asperger Autismus
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="text-center">
							<span className="mdi mdi-tag-plus" style={{ fontSize: "3rem" }} />
							<br />
							Anderer Name
						</td>
						<td>
							<br />

							<p>Kanner Autismus</p>
							<p>Kanner-Syndrom</p>
							<p>Lowfunctioning Autismus</p>
						</td>

						<td>
							<br />
						</td>

						<td>
							<br />
							<p></p>
						</td>

						<td>
							<p>Highfunctioning Autismus</p>
							Asperger-Syndrom
						</td>
					</tr>

					<tr>
						<td className="text-center">
							<span className="mdi mdi-tag" style={{ fontSize: "3rem" }} />
							<br />
							Zugehörigkeit
						</td>
						<td>
							<br />

							<p>Frühkindlicher Autismus</p>
						</td>

						<td>
							<br />

							<p>Frühkindlicher Autismus</p>
						</td>

						<td>
							<br />
							<p>Frühkindlicher Autismus</p>
							<p>Später aber kaum vom Asperger Autismus zu unterscheiden</p>
						</td>

						<td>
							<br />
							Gehört <u>nicht</u> zum Frühkindlichen Autismus
							<br />
						</td>
					</tr>

					<tr>
						<td className="text-center">
							<span
								className="mdi mdi-chart-bell-curve"
								style={{ fontSize: "3rem" }}
							/>
							<br />
							Intelligenz-Niveau
						</td>
						<td>
							<br />
							Niedrig <br />
							Wird meist als “geistige Behinderung” eingestuft
						</td>
						<td>
							<br />
							Niedrig bis hoch
						</td>
						<td>
							<br />
							Normal bis hoch
							<br /> Teilweise Hochbegabung
						</td>
						<td>
							<br />
							Normal bis hoch <br />
							Teilweise Hochbegabung
						</td>
					</tr>

					<tr>
						<td className="text-center">
							<span
								className="mdi mdi-baby-face-outline"
								style={{ fontSize: "3rem" }}
							/>
							<br />
							Erste Auffälligkeiten
						</td>
						<td>
							<br />
							10.-12.Monat
						</td>
						<td>
							<br />
							10.-12. Monat oder 3. Lebensjahr
						</td>
						<td>
							<br />
							10.-12.Monat
						</td>
						<td>
							<br />
							4. Lebensjahr
						</td>
					</tr>
					<tr>
						<td className="text-center">
							<span className="mdi mdi-eye-outline" style={{ fontSize: "3rem" }} />
							<br />
							Blickkontakt
						</td>
						<td>
							<br /> Wird gemieden
						</td>
						<td>
							<br /> Wird gemieden
						</td>
						<td>
							<br /> Wird gemieden
						</td>
						<td>
							<br /> Wird gemieden
						</td>
					</tr>
					<tr>
						<td className="text-center">
							<span className="mdi mdi-chat" style={{ fontSize: "3rem" }} />
							<br />
							Sprache
						</td>
						<td>
							Kein Spracherwerb
							<br />
							oder
							<br />
							Verzögerter Beginn
							<br />
							"Nachplappern" ohne Sinngehalt
							<br />
							Wiederholungen
						</td>
						<td>
							Kein Spracherwerb
							<br />
							oder
							<br />
							Verzögerter Beginn
							<br />
							"Nachplappern" ohne Sinngehalt
							<br />
							Wiederholungen
						</td>
						<td>
							<br />
							Beginnt verzögert
						</td>
						<td>
							<br />
							Sehr früh
							<br />
							Sehr fortgeschritten
							<br />
							Vergleichbar mit einem kleinen Professor
							<br />
							Übertrieben genau
							<br />
							Sachlich
							<br />
							Metaphern und Ironie werden nicht gut verstanden
							<br />
						</td>
					</tr>
					<tr>
						<td className="text-center">
							<span className="mdi mdi-run" style={{ fontSize: "3rem" }} />
							<br />
							Motorik
						</td>
						<td>
							<br />
							Keine den Autismus betreffenden Auffälligkeiten
						</td>
						<td>
							<br />
							Keine den Autismus betreffenden Auffälligkeiten
						</td>
						<td>
							<br />
							Keine den Autismus betreffenden Auffälligkeiten
						</td>
						<td>
							<br />
							Motorische Störungen
							<br />
							Ungeschicktheit
							<br />
							Koordinationsprobleme
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</Layout>
);

export default AboutPage;
